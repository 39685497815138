import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import Widget from '../../components/molecules/Widget';
import Button from '../../components/atoms/inputs/Button';

const defaultConversationStarterMessage = 'Hey there, Austin adventurer! 🤠 Welcome to Club Koko, your ultimate guide to all things fun and fabulous in the ATX! 🌵🍳🎸 Ready to dive into the best eats, beats, and SXSW happenings our city has to offer? Let\'s make some magic happen!';

// SPECIFIC CONFIG FOR KOKO - CHANGE THIS FOR OTHER AGENTS
const KokoWidgetConfig = {
  twinId: process.env.GATSBY_AGENT_KOKO || 'b19cc602-5721-4665-89a1-a54856a0fe53',
  aiAvatarImage:
    'https://res.cloudinary.com/fetch-ai/image/upload/v1731674921/flockx-community-app/Community%20AI%20Assets/Avatar/KokoAI_mgjy8u.png',
  defaultMessage: defaultConversationStarterMessage,
  widgetTitle: `Chat with Koko's AI`,
};

const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image:
    'https://res.cloudinary.com/fetch-ai/image/upload/v1740835004/flockx-website/Landing%20page/club-koko/club-koko-hero-02_c71i6i.jpg',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: ['AI Agent', 'SXSW', 'Austin', 'Club Koko', 'Koko', 'flockx'].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'Find Your Flock',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'best bbq austin',
  'austin coffee shops',
  'sxsw free events',
  'east austin restaurants',
  'club koko',
  'austin tech scene',
  'sxsw after parties',
  'flockx',
  'ai agent',
  'agentic',
  'multi agent',
  'personal ai agent',
];

// Custom SVG icons with white fill
const WhiteInstagramIcon = () => (
<svg id="fi_3661391" enable-background="new 0 0 20 20" height="25" viewBox="0 0 20 20" width="25" xmlns="http://www.w3.org/2000/svg"><g fill="white"><path d="m15 0h-10c-2.8 0-5 2.2-5 5v10c0 2.8 2.2 5 5 5h10c2.8 0 5-2.2 5-5v-10c0-2.8-2.2-5-5-5zm3 15c0 1.7-1.3 3-3 3h-10c-1.7 0-3-1.3-3-3v-10c0-1.7 1.3-3 3-3h10c1.7 0 3 1.3 3 3z"></path><path d="m10 5c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path><circle cx="15" cy="5" r="1"></circle></g></svg>
);

const WhiteTikTokIcon = () => (
  <svg
    width="25"
    height="28"
    viewBox="0 0 25 28"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <path
      d="M8.0694 27.99C7.6394 27.95 7.3194 27.86 6.9394 27.78C3.4094 27.06 0.639405 24.16 0.0994049 20.62C-0.610595 16.01 2.5594 11.61 7.1594 10.81C8.0194 10.66 8.8794 10.63 9.7394 10.71C9.9794 10.73 10.0894 10.78 10.0894 11.08C10.0694 12.42 10.0794 13.76 10.0894 15.1C10.0894 15.32 10.0794 15.39 9.80941 15.33C7.08941 14.62 4.4694 16.78 4.6394 19.59C4.7694 21.75 6.6194 23.47 8.79941 23.46C10.9894 23.46 12.8194 21.72 12.8994 19.55C12.9394 18.45 12.9194 17.34 12.9194 16.24C12.9194 10.94 12.9194 5.65 12.9194 0.35C12.9194 0.26 12.9194 0.17 12.9294 0.0899997C12.9294 0.0399997 12.9794 0 13.0294 0H17.3894C17.4394 0 17.4794 0.0399997 17.4894 0.0899997C17.5094 0.38 17.5094 0.67 17.5594 0.95C18.0794 4.38 20.8594 6.87 24.3294 7.01C24.5094 7.01 24.5794 7.05 24.5794 7.25C24.5794 8.61 24.5794 9.96 24.5794 11.32C24.5794 11.51 24.5294 11.56 24.3394 11.56C21.8794 11.5 19.6694 10.74 17.6794 9.3C17.6094 9.25 17.5194 9.3 17.5194 9.38C17.5194 12.64 17.5194 15.89 17.5194 19.14C17.5194 19.63 17.4894 20.12 17.4194 20.61C16.9094 24.22 13.9094 27.27 10.3094 27.84C9.9994 27.89 10.0194 27.92 9.4094 27.99C9.3594 27.99 9.3194 27.99 9.2694 27.99H8.08941H8.0694Z"
      fill="white"
    />
  </svg>
);

export default function HomePage({
  data: {
    site: {
      siteMetadata: { description, siteUrl, title },
    },
  },
}) {
  const schemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
  };

  const handleCTAButtonClick = () => {
    window?.gtag('event', 'koko_chat_cta_click', {
      event_category: 'CTA', 
      event_label: 'Koko Chat CTA',
    });

    window.open('https://community.flockx.io/api/auth/login', '_blank');
  };

  return (
    <BaseLayout
      mainSectionClass="relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass="fixed w-full z-50 bg-transparent"
    >
      <SEO
        title="Howdy, welcome to SXSW!"
        keywords={SEO_KEYWORDS}
        description="I'm Koko of A Taste of Koko - Austin's OG food blogger since 2010. Free things to do during SXSW? Best restaurants in Austin? Chat with Koko's AI."
        schemaMarkup={schemaMarkup}
      />

      <div className="relative min-h-screen">
        {/* Background with class for media query handling */}
        <div className="hero-background club-koko" />
        {/* Overlay for the hero background */}
        <div className="absolute inset-0 bg-gradient-to-r md:bg-gradient-to-r from-black/90 to-black/30 bg-gradient-to-t z-[1]" />

        {/* Content container with existing Tailwind classes */}
        <div className="relative z-10 flex pt-24 pb-16 md:pb-0 min-h-[calc(100vh-80px)]">
          <div className="w-full flex flex-col md:flex-row gap-5">
            {/* Left column - Content */}
            <div
              className="w-full md:w-1/2 order-2 md:order-1
            px-2 lg:px-6 md:pl-24 lg:pl-32
            flex items-end md:items-center
            pb-[5vh] md:pb-0"
            >
              <div
                className="w-full mx-auto md:mx-0
              max-w-xl md:max-w-2xl 2xl:max-w-4xl
              text-center md:text-left"
              >
                <h1
                  className="hidden md:block text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl
                font-bold tracking-regular text-white
                mb-4 md:mb-6"
                >
                  Howdy,
                  <br />
                  welcome to SXSW!
                </h1>

                <p
                  className="hidden md:block text-sm md:text-base lg:text-lg 2xl:text-xl
                font-regular text-white
                mb-6 md:mb-8
                leading-7 md:leading-8 lg:leading-9"
                >
                  I'm Koko of A Taste of Koko, Austin's OG food blogger since
                  2010. 
                  <br />
                  Looking for free SXSW events or Austin's hottest restaurants?
                  Chat with my AI right now and get insider tips from a local expert!
                </p>               
                <h4 className="text-lg font-bold text-white mb-2">
                 Want to build your own AI agent, just like Koko? 
                </h4>
                <Button
                    label="Create your AI Agent Now"
                    onClickHandler={handleCTAButtonClick}
                    id="Create-your-AI-Agent-Now" 
                    isKokoButton={true}
                    className="font-bold transition-colors !px-16 !py-3"
                  />
                           {/* Social Media Links */}
                <div className="mt-6 flex flex-col md:flex-row items-center justify-center md:justify-start">
                <p className="mb-3 md:mb-0 md:mr-4 text-white text-sm md:text-base lg:text-lg 2xl:text-xl leading-7 md:leading-8 lg:leading-9 text-center md:text-left">Follow A Taste of Koko</p>
        <div className="flex gap-4 items-center">
          <a href="https://www.instagram.com/atasteofkoko/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <WhiteInstagramIcon />
          </a>
          <a href="https://www.tiktok.com/@atasteofkoko" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
            <WhiteTikTokIcon />
          </a>
        </div>
           </div>
              </div>            
            </div>

            {/* Right column - Image */}
            <div className="w-full text-center mb-0 md:hidden">
              <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-0">
                Howdy, welcome to SXSW!
              </h1>
            </div>
            <div
              className="w-full md:w-1/2 order-1 md:order-2
            flex items-center justify-center mx-auto md:ml-10 px-4 md:px-6
            pb-0 pt-0 md:pt-0 my-auto"
            >
           
              <div className="flex items-center justify-center h-full w-full">
                <Widget customConfig={KokoWidgetConfig} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export const query = graphql`
  query ActivityHomePageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`;
